<template>
  <div class="admin-project">
    <div class="navbar">
      <a
        @click="onTabChange(item.value)"
        v-for="(item, index) in tabs"
        :key="index"
        :class="[tabValue === item.value && 'active']"
        href="javascript:;"
        >{{ item.title }}</a
      >
    </div>
    <div class="list">
      <mt-button v-if="!isOrgRole" @click="onAdd" type="primary" block round>发布需求</mt-button>
      <div v-infinite-scroll="onReachBottom" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
        <div v-if="list.length > 0">
          <ProjectItem :role="role" :isAdmin="true" :entity="item" v-for="(item, index) in list" :key="index" />
        </div>
        <div v-else style="color:#999;text-align:center;font-size:14px;">暂无数据...</div>
      </div>
      <div v-if="!isEnd && loading" class="loading">
        <mt-spinner color="#178CF9" type="snake"></mt-spinner>
      </div>
    </div>
  </div>
</template>
<script>
import shared from '../shared'
import { listMyProject } from '@/api/project'
import { mapGetters } from 'vuex'

const TABS = [
  { title: '待审核', value: 1 },
  { title: '发布中', value: 2 },
  { title: '洽谈中', value: 3 },
  { title: '已结束', value: 4 }
]
const TABS_FOR_ORG = [{ title: '已抢单', value: 0 }, { title: '已合作', value: 1 }, { title: '未合作', value: 2 }]

export default {
  components: {
    ProjectItem: shared.ProjectItem
  },
  data() {
    return {
      tabs: TABS,
      tabValue: 1,
      isEnd: false,
      loading: false,
      list: [],
      pageNo: 0
    }
  },
  computed: {
    ...mapGetters(['type']),
    role() {
      return this.type
    },
    isOrgRole() {
      return this.role === 'org' || this.role === 'employee'
    }
  },
  mounted() {
    if (this.isOrgRole) {
      this.tabValue = 0
      this.tabs = TABS_FOR_ORG
    }
  },
  methods: {
    onTabChange(value) {
      this.tabValue = value
      this.refresh()
    },
    refresh() {
      this.isEnd = false
      this.loadData(1)
    },
    async loadData(pageNo) {
      this.loading = true
      try {
        const extraParams = {}
        if (this.tabValue !== 0 || this.isOrgRole) {
          extraParams['status'] = this.tabValue
        }
        const { success, result } = await listMyProject({
          pageSize: 10,
          pageNo,
          ...extraParams
        })
        const totalPage = result.pages
        if (success) {
          result.records = result.records.map(item => {
            item.createTime = item.createTime.slice(0, 10)
            item.endTime = item.endTime.slice(0, 10)
            return item
          })
          if (pageNo === 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
          console.log(this.list.length)
          this.pageNo = pageNo
          if (this.pageNo >= totalPage) {
            this.isEnd = true
          }
        }
      } catch (error) {
        this.isEnd = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      if (this.isEnd) return
      this.loadData(this.pageNo + 1)
    },
    onAdd() {
      this.$router.push('/admin/project/edit/0')
    }
  }
}
</script>
<style lang="less" scoped>
.admin-project {
  min-height: 100vh;
  background-color: #f5f5f5;
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    z-index: 10;
    a {
      flex: 1;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      position: relative;
      &.active {
        color: #178cf9;
        &::after {
          content: '';
          width: 15px;
          height: 3px;
          background: rgba(23, 140, 249, 1);
          border-radius: 1px;
          position: absolute;
          bottom: 0;
          left: 38px;
        }
      }
    }
  }

  .list {
    padding: 12px 15px;
    margin-top: 44px;
    & > .mint-button {
      margin-bottom: 12px;
      background-color: #178cf9;
      border-radius: 21px;
      width: 100%;
      font-size: 15px;
    }
  }
  .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
</style>
