var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-project" }, [
    _c(
      "div",
      { staticClass: "navbar" },
      _vm._l(_vm.tabs, function(item, index) {
        return _c(
          "a",
          {
            key: index,
            class: [_vm.tabValue === item.value && "active"],
            attrs: { href: "javascript:;" },
            on: {
              click: function($event) {
                return _vm.onTabChange(item.value)
              }
            }
          },
          [_vm._v(_vm._s(item.title))]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "list" },
      [
        !_vm.isOrgRole
          ? _c(
              "mt-button",
              {
                attrs: { type: "primary", block: "", round: "" },
                on: { click: _vm.onAdd }
              },
              [_vm._v("发布需求")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.onReachBottom,
                expression: "onReachBottom"
              }
            ],
            attrs: {
              "infinite-scroll-disabled": "loading",
              "infinite-scroll-distance": "10"
            }
          },
          [
            _vm.list.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.list, function(item, index) {
                    return _c("ProjectItem", {
                      key: index,
                      attrs: { role: _vm.role, isAdmin: true, entity: item }
                    })
                  }),
                  1
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#999",
                      "text-align": "center",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("暂无数据...")]
                )
          ]
        ),
        !_vm.isEnd && _vm.loading
          ? _c(
              "div",
              { staticClass: "loading" },
              [
                _c("mt-spinner", { attrs: { color: "#178CF9", type: "snake" } })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }